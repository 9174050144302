// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-art-js": () => import("./../src/pages/art.js" /* webpackChunkName: "component---src-pages-art-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-me-js": () => import("./../src/pages/me.js" /* webpackChunkName: "component---src-pages-me-js" */),
  "component---src-templates-art-post-js": () => import("./../src/templates/art-post.js" /* webpackChunkName: "component---src-templates-art-post-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

